<template>
    <div class="politicalLaw">
        <div class="banner">
            <img v-if="queryId==595" src="../assets/images/policy/banner.png" class="bimg" alt="" srcset="" width="100%"> 
			<img v-if="queryId==596" src="../assets/images/firm/banner.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/" v-if="queryId==595">政策法规</a><a href="/" v-if="queryId==596">企业风采</a>
            </div>
        </div>
        <div class="mileage">
            <div class="mileage-content">
                <div class="content">
                    <div v-for="(item,index) in politicaList" :key="index" @click="goDetail(item)">
                        <hr >
                        <div class="item-content">
                            <div class="item-left">
                              <p class="time">{{ item.cjsj.split('-').slice(1).join('-') }}</p>  
                              <p class="year flexc">{{ item.cjsj.split('-')[0] }}</p>
                             </div>
                            <div style="color: #a7a7a7;" class="line-style">——</div>
                            <div class="item-mind">
                                <p class="title">{{ item.bt  || item.gsmc}}</p>
                                <p class="description"> {{ item.nr.substring(0,100) }}</p>
                            </div>
                            <div class="item-right">
                                <img :src="imgurl+item.tpDepotCompressUrl[0]" alt="" srcset="">
                            </div>
                        </div>
                        <hr v-if="index === politicaList.length - 1">
                    </div>
                </div>
            </div>
        </div>

       <div class="pages">
            <el-pagination
                :page-size="6"
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="changesome"
                >
            </el-pagination>
       </div>

    </div>
</template>

<script>
	import Swiper from "swiper";
	import { setTimeout } from "timers";
	import request from "../utils/request.js";
	import { apiUrl } from '@/config'
	import {EventBus} from "../utils/event-bus.js";
export default {
    data(){
        return {
			queryId:'',
			formId:'',
			inputValue:'',
			total:0,
			pageNum:1,
			imgurl:apiUrl,
            current:1,
            politicaList:[
                // {
                //    time:"01-07",
                //    year:"2024" ,
                //    title:"湖北省中小企业数字化转型发展联盟",
                //    description:"2023年6月15日，有湖北省经济和信息厅指导，湖北省中小企业服务中心、大唐互联科技（武汉）有限公司共同主办的湖北省数字紧急政策宣贯培训暨",
                //    img:require('@/assets/images/policy/lc1.png')
                // }
        
            ],

           
        }
    },
    methods:{
        changesome(current){
               var that = this;
               that.pageNum = current;
			   that.getZzfg()
        },
        goDetail(item){ console.log(this.queryId,'this.queryId跳转')
			if(this.queryId==595){
				 this.$router.push({path:"searchDetails/"+this.queryId+"/"+item.id,})
			}
			if(this.queryId==596){
				 this.$router.push({path:"searchDetails/"+this.queryId+"/"+item.id,})
			}
			if(this.queryId==590){
				 this.$router.push({path:"expertdetails/"+this.queryId+"/"+item.id,})
			}
           
        },
		/**政治法规列表**/
	getZzfg(){
		var that=this
		var obj=JSON.parse(getStorage('searchParams'))
		if(obj){
		that.formId=obj.formId
		that.queryId=obj.queryId
		that.inputValue=obj.inputValue
		}
		var parm=
			{
			    "groupval": "",
			    "pageSize": 6,
			    "pageNum": that.pageNum,
			    "orderByColumn": "id",
			    "isAsc": "desc",
			    "formId": that.formId,
			    "xssx": "",
			    "queryId":that.queryId,
			    "queryStr": "",
			    "isId": 0
			}
			if(that.queryId==595){
				parm['bt']=decodeURIComponent(that.inputValue) 
			}
			if(that.queryId==596){
				parm['gsmc']=decodeURIComponent(that.inputValue) 
			}
				
		request.post('/spms/previewQuery/list',parm).then(res =>{
			if(res.code==200){
				const {data}=res
				that.politicaList=data.rows;
				that.total=data.total
				
			}
		})
	}
		
    },
    mounted(){
		var that=this
		EventBus.$on('searchParams',function(val){
			console.log(val,'监听')
			var obj=JSON.parse(val)
			that.formId=obj.formId
			that.queryId=obj.queryId
			that.inputValue=obj.inputValue
			that.getZzfg()
		})
			console.log('11searchParams')
		console.log(JSON.parse(getStorage('searchParams')),'searchParams')
		that.getZzfg()
	},
	
}
</script>
<style lang="scss" scoped>
.politicalLaw{
    box-sizing: border-box;
    .pages{
        margin:40px 0;
        display:flex;
        justify-content: center;
    }
    .banner {
        width: 100%;
        height:300px;
        .bimg{
            width: 100%;
            height:100%;
        }
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        .orientation-content{
            width: 1200px;
            height: 100%;
            line-height: 60px;
            margin: 0 auto;
            color:#666666
        }
    }
    .mileage{
        .content{}
        .mileage-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            padding-top: 15px;
            .content{
                .item-content{
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 0px;
                    .item-left{
                        .time{
                            font-size: 20px;
                            color: #2270b6;
                        }
                        .year{
                               color: #a7a7a7; 
                               border: 1px solid #a7a7a7;
                        }
                    }
					.item-right{
						img{
							width: 270px;
						}
					}
                    .item-mind{
                        width: 720px;
                        .title{
                            color: #005aab;
                            font-size: 16px ;
                            line-height: 30px   ;
                        }   
                        .description{
                            line-height: 25px;
                            margin-top: 30px;
                            font-size: 15px;
                            color:#999999;
                            text-overflow: ellipsis; ///超出部分省略号显示
                            display: -webkit-box; //弹性盒模型
                            -webkit-box-orient: vertical; //上下垂直
                            -webkit-line-clamp: 2; //自定义行数
                        }
                    }
                }
            }
        }
    }
        .pages{
        margin:40px 0;
        display:flex;
        justify-content: center;
    }
}
</style>
